// material-ui
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import * as Actions from "actions";
// components
import App from "components/App";
import Centrifugo from "components/Centrifugo";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import theme from "themes/theme";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getStudioSessionMercury: (...args) =>
    dispatch(Actions.getStudioSessionMercury(...args)),
  getProjectMercury: (...args) => dispatch(Actions.getProjectMercury(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
});

class Root extends Component {
  static propTypes = {
    getStudioSessionMercury: PropTypes.func,
    getProjectMercury: PropTypes.func,
  };

  // componentWillMount() {
  //   const dm =
  //     window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
  //   dm.addListener(this.darkModeChanged.bind(this));
  // }

  darkModeChanged(dm) {
    const { setDarkMode } = this.props;
    setDarkMode(dm.matches);
  }

  render() {
    const { getStudioSessionMercury, getProjectMercury, app } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    const muiTheme = createMuiTheme({
      palette: {
        ...theme,
        type: urlParams.dark ? "dark" : "light",
      },
      typography: {
        fontFamily: ["poppins, sans-serif"].join(","),
      },
      overrides: {
        MuiTypography: {
          // Name of the component ⚛️ / style sheet
          root: {
            // Name of the rule
            color: app.darkMode ? "white" : "black", // Some CSS
          },
        },
      },
    });

    const api = {
      getStudioSessionMercury,
      getProjectMercury,
    };
    return (
      <MuiThemeProvider theme={muiTheme}>
        <Centrifugo>
          <App api={api} />
        </Centrifugo>
      </MuiThemeProvider>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
