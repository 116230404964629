export default (theme) => {
  const darkMode = theme.palette.type === "dark";
  return {
    root: {
      display: "flex",
      background: darkMode ? "#212121" : "white",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
  };
};
