// import PropTypes from 'prop-types';
// material-ui
import { Divider, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import Centrifuge from "centrifuge";
import { CENTRIFUGO_TOKEN, CENTRIFUGO_URL } from "config";
import { getJsonFromUrl } from "helpers";
import React from "react";
import Chart from "react-apexcharts";
import SockJS from "sockjs-client";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export default function App({ api }) {
  const [stats, setStats] = React.useState();
  const [broken, setBroken] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [width, setWidth] = React.useState(getWidth());
  const theme = useTheme();
  const classes = useStyles(styles);

  const wsJobUpdate = (m) => {
    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.workSessionID)
      api
        .getStudioSessionMercury(urlParams.workSessionID)
        .then((r) => setStats(r.payload));

    if (urlParams.projectID)
      api
        .getProjectMercury(urlParams.projectID)
        .then((r) => setStats(r.payload));
  };

  React.useEffect(() => {
    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.workSessionID && urlParams.token) {
      api.getStudioSessionMercury(urlParams.workSessionID).then((r) => {
        setStats(r.payload);
        setLoading(false);
      });
      const centrifuge = new Centrifuge(CENTRIFUGO_URL, {
        sockjs: SockJS,
      });
      centrifuge.setToken(CENTRIFUGO_TOKEN);
      centrifuge.connect();
      centrifuge.subscribe(`rhapsody:${urlParams.token}`, wsJobUpdate);

      const resizeListener = () => {
        // change width from the state object
        setWidth(getWidth());
      };
      window.addEventListener("resize", resizeListener);

      return () => {
        // remove resize listener
        window.removeEventListener("resize", resizeListener);
      };
    } else if (urlParams.projectID) {
      const centrifuge = new Centrifuge(CENTRIFUGO_URL, {
        sockjs: SockJS,
      });
      centrifuge.setToken(CENTRIFUGO_TOKEN);
      centrifuge.connect();
      centrifuge.subscribe(`rhapsody:${urlParams.token}`, wsJobUpdate);
      api.getProjectMercury(urlParams.projectID).then((r) => {
        setStats(r.payload);
        setLoading(false);
      });
    } else {
      setBroken(true);
    }
  }, []);

  const excludeFromChart = [];

  let labels =
    stats?.currentStages?.reduce((accumulator, currentV) => {
      return [...accumulator, currentV.name || "Unkown"];
    }, []) ?? [];

  let colors =
    stats?.currentStages?.reduce(
      (accumulator, currentV) => [...accumulator, currentV.color || "#bdbdbd"],
      []
    ) ?? [];

  let series =
    stats?.currentStages?.reduce(
      (accumulator, currentV) => [...accumulator, currentV.jobCount],
      []
    ) ?? [];

  if (broken) {
    return (
      <div className={classes.root}>
        <div style={{ textAlign: "center" }}>
          <i style={{ fontSize: 60 }} class="fad fa-spider-black-widow"></i>
          <Typography>Something went wrong!</Typography>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      style={{
        flexDirection: width < 350 ? "column" : "row",
      }}
    >
      <div
        style={{
          flex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Skeleton variant="circle" height={150} width={150} />
        ) : (
          <Chart
            options={{
              labels,
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  customScale: 0.8,
                },
              },
              stroke: {
                show: false,
                width: 0,
              },
              tooltip: {
                enabled: true,
                fillSeriesColor: false,
                y: {
                  formatter: (val) => `${val}%`,
                },
              },
              legend: {
                show: false,
              },
              colors,
            }}
            series={series}
            type={"donut"}
            height={230}
          />
        )}
      </div>
      <div
        style={{
          flex: width < 350 ? 3 : 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <>
          {loading ? (
            <>
              <Skeleton width={120} height={15} style={{ marginBottom: 6 }} />
              <Skeleton width={130} height={15} style={{ marginBottom: 6 }} />
              <Skeleton width={115} height={15} style={{ marginBottom: 6 }} />
              <Skeleton width={120} height={15} style={{ marginBottom: 6 }} />
              <Skeleton width={130} height={15} style={{ marginBottom: 6 }} />
              <Skeleton width={115} height={15} style={{ marginBottom: 6 }} />
            </>
          ) : (
            stats?.currentStages?.map((s) => (
              <div>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {s.icon ? (
                    <img src={s.icon} style={{ height: 12, paddingRight: 8 }} />
                  ) : (
                    <i class="fad fa-chair" style={{ paddingRight: 8 }}></i>
                  )}{" "}
                  {s.jobCount} {s.name}
                </Typography>
              </div>
            ))
          )}
        </>
        {stats?.formerStages?.length ? (
          <div style={{ marginTop: 20 }}>
            <Divider />
            <Typography variant="caption" color="textSecondary">
              Previously removed
            </Typography>
            {stats?.formerStages?.map((s) => (
              <div>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {s.icon ? (
                    <img src={s.icon} style={{ height: 12, paddingRight: 8 }} />
                  ) : (
                    <i class="fad fa-chair" style={{ paddingRight: 8 }}></i>
                  )}{" "}
                  {s.jobCount} {s.name}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          []
        )}
      </div>
    </div>
  );
}
