import * as Api from "api";
import {
  GET_PROJECT_MERCURY,
  GET_STUDIO_SESSION_MERCURY,
} from "constants/studioSessions";
import { networkAction } from "helpers/network/networkAction";

export const getStudioSessionMercury = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_MERCURY,
    Api.getStudioSessionMercury,
    [id]
  );

export const getProjectMercury = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_MERCURY, Api.getProjectMercury, [id]);
