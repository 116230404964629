import { GET } from "constants/methods";

const service = "rhapsody";
const studioSessions = `/${service}/workSessions`;
const projects = `/${service}/projects`;

export function getStudioSessionMercury(id) {
  const url = `${studioSessions}/${id}/mercury`;
  return {
    method: GET,
    url,
  };
}

export function getProjectMercury(id) {
  const url = `${projects}/${id}/mercury`;
  return {
    method: GET,
    url,
  };
}
