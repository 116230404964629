// custom components
import Root from "components/Root";
import React from "react";
import "./App.css";

function App() {
  return <Root />;
}

export default App;
